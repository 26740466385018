/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak specializes in high-end, ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "custom-built hearing aids"), " for those with moderate to severe hearing loss. Founded in 1947, this Swiss company developed into one of the most advanced manufacturers of wireless communications devices – and hearing aids are a key part of their current product range. The Virto collection is relatively new, and extremely advanced. In 2009, ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/",
    className: "c-md-a"
  }, "Phonak"), " merged with Advanced Bionics, a company that specializes in cochlear implants. By combining their expertise in auditory systems, they came up with a suite of devices that revolutionized the hearing aid industry."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-two-types-of-phonak-virto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-two-types-of-phonak-virto",
    "aria-label": "the two types of phonak virto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Two Types of Phonak Virto"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak has released two variations of their Virto hearing aid with slightly different features."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The Phonak Virto V"), " – Compact and virtually invisible when placed in the ear, the Virto V is customizable to suit the needs of every wearer. The V series can be molded to fit the exact shape of the wearer’s ear, resulting in a much more comfortable fit. Its processor allows for individualized sound manipulation, adapting automatically to environments frequented by the wearer. Using advanced processing technology, the Virto V fits any lifestyle, without the need for lengthy calibration appointments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The Phonak Virto B"), " – The Virto B is also a custom-made hearing aid that can be molded to ensure the most comfortable fit. The difference between the Virto V and B is the presence of what Phonak calls “biometric calibration,” which detects the source of any given sound. The Virto B has over 1,600 biometric sensors that plot the way the wearer hears, creating one of the most individualized hearing aid experiences."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "discreet-flexible-and-easy-to-use",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#discreet-flexible-and-easy-to-use",
    "aria-label": "discreet flexible and easy to use permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Discreet, Flexible and Easy to Use"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both models in the Virto range have been created with cosmetics and comfort at the forefront. Customers can choose from 10 different colors, letting them find a tone which matches their skin. That way, the hearing aid will be virtually invisible while it’s in the ear. Both models come as In-The-Ear (ITE) hearing aids, as well as in a range of different sizes. The Virto V ranges from the ultra-small Nano (suitable for mild to moderate hearing loss), through V-10 NW O and the V-10 O, which are optimized for wireless streaming. There are also larger options for more severe degrees of hearing loss. Meanwhile, the Virto B boasts six different sizes: a compact version (the B-10 NW O), two larger options (the B-10 and B-10 O) and versions for severe hearing loss like the B-13. Therefore, even if you have moderate to severe hearing loss, you won’t need to wear a bulky hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "user-friendly-practical-features",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#user-friendly-practical-features",
    "aria-label": "user friendly practical features permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "User-Friendly, Practical Features"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ease of use has been built into the Virto design. For example, the Virto B collection uses Zinc Air batteries, which are easily rechargeable. But this convenience would mean very little if the sound quality produced by the Virto series didn’t measure up. Thankfully, both the B and V ranges produce superior sound clarity. However, the B range (which uses “Belong” processing software) tends to deliver slightly richer sound than the V series (which uses the older “Venture” software). For everyday users, the distinction might not be easy to discern, but those who prize perfect balance and pure sound will notice the difference. What users will notice is how the Belong processor adapts quickly and smoothly to different environments – something that in the past was achievable only by a manual setting."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "accessorize-your-phonak-virto-to-suit-your-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#accessorize-your-phonak-virto-to-suit-your-life",
    "aria-label": "accessorize your phonak virto to suit your life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Accessorize Your Phonak Virto to Suit Your Life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Both Phonak Virto hearing aids can be enhanced by Phonak accessories. For example, the TVLink accessory plugs into your Smart TV and turns your Virto hearing aid into a set of wireless headphones. Then there’s the ComPilot, which clips onto shirts or bags, turning your hearing aid into a portable, wireless streaming device. Finally, there’s the DECT, a tool that directs phone calls from your smartphone straight to your hearing aids. In a world where streaming and wireless technology are so mainstream, ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories",
    className: "c-md-a"
  }, "these accessories can make a huge contribution"), " to the quality of your personal and professional lives."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Phonak Virto now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
